<template>
  <div>
    {{ Areas }}
  </div>
</template>
<script>
  import {getArea} from "@api/enzocard/services/area/area.api";

  export default {
    data: () => ({
      Areas: '',
    }),
    props: {
      areaIds: {
        type: Array,
        default: () => []
      }
    },
    created () {
        if (this.areaIds.length > 0) {
          const requests = [];

          for (const i in this.areaIds) {
        //    requests.push(getArea(id));
            getArea(this.areaIds[i]).then((response) => {
              this.Areas += parseInt(i) === this.areaIds.length - 1 ? response.data.name : response.data.name + ', ';
            })
          }
        }
    }
  }
</script>
