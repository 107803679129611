import httpClient from "@api/enzocard/httpClient";

export const _get = (URL) => {
  return httpClient.get(URL)
    .then(response => {
      return new Promise((resolve) => {
        resolve(response);
      });
    })
    .catch((error) => {
      return _handleErrors(error)
        .then((response) => {
          return Promise.resolve(response);
        })
        .catch((exception) => {
          return Promise.reject(exception);
        })
        ;
    })
    ;
}

export const _post = (URL, data) => {
  return httpClient.post(URL, data)
    .then(response => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return _handleErrors(error)
        .then((response) => {
          return Promise.resolve(response);
        })
        .catch((exception) => {
          return Promise.reject(exception);
        })
        ;
    })
    ;
}

export const _put = (URL, data) => {
  return httpClient.put(URL, data)
    .then(response => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return _handleErrors(error)
        .then((response) => {
          return Promise.resolve(response);
        })
        .catch((exception) => {
          return Promise.reject(exception);
        })
        ;
    })
    ;
}

export const _delete = (URL) => {
  return httpClient.delete(URL)
    .then(response => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return _handleErrors(error)
        .then((response) => {
          return Promise.resolve(response);
        })
        .catch((exception) => {
          return Promise.reject(exception);
        })
        ;
    })
    ;
}

const _handleErrors = (error) => {
  //todo for all errors 404 - 500 ....
  if (error.response.status !== 401 && error.response.status !== 400 && error.response.status !== 422) {
    return Promise.reject(error);
  } else if (error.response.status === 400 || error.response.status === 422) {
    return Promise.reject(error);
  } else {
    console.error('unkown error :' + error);
    return Promise.reject(error);
  }
}
