import {_delete, _get, _post, _put} from "@api/enzocard/services/httpService";

const URI = 'areas/';

export const postArea = (area) => _post(URI, area);

export const putArea = (area) => _put(URI + area.id  + '/', area);

export const deleteArea = (id) => _delete(URI + id + '/');

export const getAreas = () => _get(URI);

export const getArea = (id) => _get(URI + id);
