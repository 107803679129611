import axios from 'axios';
import store from "@/plugins/store";
import Bus from "@/utils/bus";
import {isNotUndefinedAndNotNull} from "@/utils/classes";

let ENZO_CARD_URL = null !== store.getters['auth/currentClub'].accessControlService ? store.getters['auth/currentClub'].accessControlService.apiUrl : '';

let httpClient = axios.create({
  baseURL: ENZO_CARD_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: "Token " + JSON.parse(localStorage.getItem('enzocard-token'))
  }
});

Bus.$on('on:current-club-loaded', () => {
  const clubServiceControl = JSON.parse(localStorage.getItem('current-club')).accessControlService;

  ENZO_CARD_URL = isNotUndefinedAndNotNull(clubServiceControl) ? clubServiceControl.apiUrl : '';

  httpClient.defaults.headers.Authorization = ENZO_CARD_URL ? "Token " + clubServiceControl.apiAuthenticationToken : null;
  httpClient.defaults.baseURL = ENZO_CARD_URL;
});

export default httpClient;
